import { Fragment, useRef, useState } from "react";
import UserInfo from "./UserInfo";
// import {decodedWord} from "../../DataStore/Languages/LanguageDecrypt";
import { connect } from "react-redux";
import axios from "axios";
import { LOGOUTURL } from "../../DataStore/ApiConstant";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
// import { useNavigate } from "react-router-dom";
import LanguageSwitcher from "./LanguageSwitcher";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import { useNotification , useNotifyView} from "../../services/notification";
import { formatString } from "../../utils";
import { nanoid } from "nanoid";
import { useAtom } from "jotai";
import { reRenderCashier } from "../../DataStore/atom";


const AfterLoginContent = ({
  LanguageContent,
  setModalState,
  userDta,
  userSession,
  openPopup,
  openGeneralModal,
  fundsTransfer,
  setCashierType,
}) => {
  const [isNotificationDropdownOpen, setIsNotificationDropdownOpen] = useState(false);
  const [isNotificationUpdating, setIsNotificationUpdating] = useState(false);
  const history = useHistory();
  const notification = useNotification();

  const [, setRenderCashier] = useAtom(reRenderCashier);
  // const navigate = useNavigate();
  
  const logoutUser = (e) => {
    e.preventDefault();
    axios
      .get(LOGOUTURL)
      .then((resp) => {
        localStorage.removeItem('login')
        history.push('/');
        // window.location.href = '/'

      })
      .catch((err) => {});
  };

  const userDropdown = useRef(null);
  const mobileuserDropdown = useRef(null);

  const openUserDropdown = () => {
    userDropdown.current.classList.toggle("show-userMenu");
    mobileuserDropdown.current.classList.toggle("show-userMenu");
  };

  const notifyView = useNotifyView(
    {
      onSuccess: () => {
        notification.refetch();
      },
    }
  ); 
  const handleNotificationClick = async (notificationId) => {
    if (isNotificationUpdating) {
      return; 
    }
    setIsNotificationUpdating(true);

    try {
      await notifyView.mutateAsync(notificationId);
      notification.refetch();
    } finally {
      setIsNotificationUpdating(false);
    }
   
    setIsNotificationDropdownOpen(false);
  };
  const toggleNotificationDropdown = () => {
    setIsNotificationDropdownOpen((prev) => !prev);
  };
  return (
    <>

      <div className="d-none d-sm-block me-1">
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div class="item p-2 flex-shrink-0 align-self-sm-center">
            <Link to={"/cashier"}>
              <button
                type="button"
                class="btn btn-warning joinTopbutton text-end"
                onClick={(e) => { 
                  setRenderCashier(nanoid())
                }}
              >
                <TranslatesContent contentKey="deposit" />
              </button>
            </Link>
          </div>
          <div
            // onClick={() => openUserDropdown()}
            className="item p-lg-1 align-self-center icon"
          >
            <li className="nav-list__item notification">
            {/* <span class="icon" > */}
              <span style={{color:"white"}}>LKR</span>
             &nbsp;{" "}
            {/* </span> */}
            {fundsTransfer &&
              fundsTransfer.data.data &&
              new Intl.NumberFormat().format(Math.max(0, fundsTransfer.data.data.wallet.balance - fundsTransfer.data.data.bonus_given.balance))}
               <ul className="drop-down">
                  {/* <li className="spacer">
                    <span className="arrow" />
                  </li> */}
                  {/* <li className="drop-down__item">
                    <span className="title">LKR</span>
                    <span className="value"> {userDta &&
                     userDta.amount &&
                     new Intl.NumberFormat().format(userDta.amount)}</span>
                  </li> */}
                  <li className="drop-down__item">
                    <span className="title">Wallet</span>
                    <span className="value">{fundsTransfer &&
                                    fundsTransfer.data &&
                                    fundsTransfer.data.data.wallet &&
                                    new Intl.NumberFormat().format(
                                    fundsTransfer.data.data.wallet.fundBalance
                                  )}</span>
                  </li>
                  <li className="drop-down__item">
                    <span className="title">Bonus</span>
                    <span className="value"> {fundsTransfer &&
                                  fundsTransfer.data &&
                                  fundsTransfer.data.data.bonus_given &&
                                  new Intl.NumberFormat().format(
                                  fundsTransfer.data.data.bonus_given.balance)}</span>
                  </li>
                  <li className="drop-down__item">
                    <span className="title">OTP</span>
                    <span className="value">
                      {fundsTransfer &&
                                    fundsTransfer.data &&
                                    fundsTransfer.data.data.otpcash &&
                                    new Intl.NumberFormat().format(
                                      fundsTransfer.data.data.otpcash.balance
                                    )}
                                    </span>
                  </li>
                  <li className="drop-down__item">
                    <span className="title">Non Cash</span>
                    <span className="value">{fundsTransfer &&
                                    fundsTransfer.data &&
                                    fundsTransfer.data.data.noncash &&
                                    new Intl.NumberFormat().format(
                                      fundsTransfer.data.data.noncash.balance
                                    )}</span>
                  </li>

                  {/* <li className="drop-down__item">
                    <span className="title">Total</span>
                    <span className="value">{fundsTransfer &&
                        fundsTransfer.data &&
                        fundsTransfer.data.data.total &&
                        new Intl.NumberFormat().format(
                          fundsTransfer.data.data.total.balance
                        )}</span>
                  </li> */}
                </ul>
            </li>
            
          </div>
          <div className="item p-lg-1 align-self-center position-relative">
            <li class="nav-list__item notification">
            <a href="#" class="icon"><span class="icon" onClick={toggleNotificationDropdown}>
                <i className="fa fa-bell"></i>
                {
                  // JSON.stringify(parseInt(notification.data.notifyCount)< 0 ? "null":"ssad")
                  notification.data && parseInt(notification.data.notifyCount) > 0 ? 
                    <span class="total"> {notification.data && notification.data.notifyCount}</span> : <span ></span>

                }
              </span></a>
              {isNotificationDropdownOpen && (
              <ul class="drop-down">
                        {/* <li class="spacer">
                            <span class="arrow"></span>
                        </li> */}
                        {/* <li class="drop-down__item header"> */}
                        {/* <li class="drop-down__item">
                            <span class="title" style={{
                              color:"#000", 
                              fontWeight: "600",
                        }}>Notifications</span>
                        </li> */}
                        {notification.isError === false && notification?.data?.userNotify?.length > 0 && (
                          <>
                            {notification?.data?.userNotify?.map((userNotification) => (
                              
                              <li class="drop-down__item" key={userNotification.id} onClick={() => handleNotificationClick(userNotification.id)} >
                                {userNotification.notification}
                              </li>
                            ))}
                          </>
                        )}
                        {notification.isError === false && notification?.data?.commonNotify?.length > 0 && (
                          <>
                            {notification?.data?.commonNotify?.map((commonNotification) => (
                              <li class="drop-down__item" key={commonNotification.id} onClick={() => handleNotificationClick(commonNotification.id)}>
                                {commonNotification.notification}
                              </li>
                            ))}
                          </>
                        )}

                      {notification.isError === false &&
                        (!notification?.data?.userNotify || notification?.data?.userNotify?.length === 0) &&
                        (!notification?.data?.commonNotify || notification?.data?.commonNotify?.length === 0) && (
                          <li class="drop-down__item">No notifications available</li>
                        )}
                </ul>
              )}
            </li>
          </div>
          <a
        href=""
        className="mx-2 icon"
        id="defaultDropdown2"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
      >
        <i className="fa fa-user"></i>
      </a>
          <div className="item p-lg-1 align-self-center icon">
            <a
              href=""
              onClick={(e) => logoutUser(e)}
              className="heder-rounded-icon"
            >
              <i className="fa fa-power-off"></i>
            </a>
          </div>
        </div>
      </div>

      <div className="container d-sm-none">


      <div className="col-12 pb-2 mt-3">
        <div className="row">
          {/* $$$$$ Wallet with Drop Down  $$$$$ */}
          <div className="col-6 align-self-center text-start">
            <li className="nav-list__item notification">
              <i className="fa fa-coins" aria-hidden="true" /> {fundsTransfer &&
                     fundsTransfer.data &&
                     new Intl.NumberFormat().format( Math.max(0, fundsTransfer.data.data.wallet.balance - fundsTransfer.data.data.bonus_given.balance)) }
              <ul className="drop-down" style={{ left: 0 }}>
                {/* <li className="spacer">
                  <span className="arrow" style={{ left: 10 }} />
                </li> */}
                {/* <li className="drop-down__item">
                  <span className="title">LKR</span>
                  <span className="value">{userDta &&
                     userDta.amount &&
                     new Intl.NumberFormat().format(userDta.amount)}</span>
                </li> */}
                <li className="drop-down__item">
                  <span className="title">Wallet</span>
                  <span className="value">{fundsTransfer &&
                              fundsTransfer.data &&
                              fundsTransfer.data.data.wallet &&
                              new Intl.NumberFormat().format(
                              fundsTransfer.data.data.wallet.fundBalance
                            )}</span>
                </li>
                <li className="drop-down__item">
                  <span className="title">Bonus</span>
                  <span className="value"> {fundsTransfer &&
                            fundsTransfer.data &&
                            fundsTransfer.data.data.bonus_given &&
                            new Intl.NumberFormat().format(
                            fundsTransfer.data.data.bonus_given.balance)}
                        </span>
                </li>
                <li className="drop-down__item">
                    <span className="title">OTP</span>
                    <span className="value">
                      {fundsTransfer &&
                                    fundsTransfer.data &&
                                    fundsTransfer.data.data.otpcash &&
                                    new Intl.NumberFormat().format(
                                      fundsTransfer.data.data.otpcash.balance
                                    )}
                                    </span>
                  </li>
                <li className="drop-down__item">
                  <span className="title">Non Cash</span>
                  <span className="value">{fundsTransfer &&
                              fundsTransfer.data &&
                              fundsTransfer.data.data.noncash &&
                              new Intl.NumberFormat().format(
                                fundsTransfer.data.data.noncash.balance
                              )}</span>
                </li>

                {/* <li className="drop-down__item">
                  <span className="title">Total</span>
                  <span className="value">{fundsTransfer &&
                  fundsTransfer.data &&
                  fundsTransfer.data.data.total &&
                  new Intl.NumberFormat().format(
                    fundsTransfer.data.data.total.balance
                  )}</span>
                </li> */}
              </ul>
            </li>
          </div>
          {/* $$$$$ Wallet with Drop Down END $$$$$ */}
          {/* $$$$$ Notifications Drop Down $$$$$ */}
          <div className="col-6 align-self-center text-end ">
            <a
              href="#"
              className="position-relative mx-2 icon nav-list__item notification"
            >
              <span className="icon" onClick={toggleNotificationDropdown}>
                <i className="fa fa-bell" />
                <span className="total"> {notification.data && notification.data.notifyCount}</span>
              </span>
              {isNotificationDropdownOpen && (
              <ul class="drop-down">
                        {/* <li class="spacer">
                            <span class="arrow"></span>
                        </li> */}
                        {/* <li class="drop-down__item header"> */}
                        {/* <li class="drop-down__item">
                            <span class="title" style={{
                          color:"#000", 
                          fontWeight: "600",
                        }}
                        >Notifications</span>
                        </li> */}
                        {notification.isError === false && notification?.data?.userNotify?.length > 0 && (
                          <>
                            {notification?.data?.userNotify?.map((userNotification) => (
                              <li class="drop-down__item" key={userNotification.id} onClick={() => handleNotificationClick(userNotification.id)}>
                                {userNotification.notification}
                              </li>
                            ))}
                          </>
                        )}
                        {notification.isError === false && notification?.data?.commonNotify?.length > 0 && (
                          <>
                            {notification?.data?.commonNotify?.map((commonNotification) => (
                              
                              <li class="drop-down__item" key={commonNotification.id} onClick={() => handleNotificationClick(commonNotification.id)}>
                                {commonNotification.notification}
                              </li>
                            
                            ))}
                          </>
                        )}

                      {notification.isError === false &&
                        (!notification?.data?.userNotify || notification?.data?.userNotify?.length === 0) &&
                        (!notification?.data?.commonNotify || notification?.data?.commonNotify?.length === 0) && (
                          <li class="drop-down__item">No notifications available</li>
                        )}
                    </ul>
                    )}
                    </a>
            <span className="icon">
            <a
            href=""
            className="mx-2 icon"
            id="defaultDropdown2"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
          >
            <i className="fa fa-user"></i>
          </a>
          <a
            href=""
            onClick={(e) => logoutUser(e)}
            className="heder-rounded-icon"
            >
            <i className="fa fa-power-off"></i>
          </a>
          </span>  
          </div>
          {/* $$$$$ Notifications Drop Down END $$$$$ */}
        </div>
      </div>


        
        
      </div>
    </>
  );
};

const maStateTorops = (state) => {
  return {
    LanguageContent: state.SiteLanguages,
    fundsTransfer: state.FundTransfers,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, ownProps, stateProps, dispatchProps);
};

export default connect(maStateTorops, mergeProps)(AfterLoginContent);
