import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import HeaderComponent from "../Header/HeaderComponent";
import MainBanner from "../Banners/MainBanner";
import { useEffect, useRef } from "react";
import FooterComponent from "../Footer/FooterComponent";
import UpiPayment from "../Deposit/UpiPayment";
import Bankayment from "../Deposit/Bankayment";
import SampathBankayment from "../Deposit/SampathBankayment";
import ByHandPayment from "../Deposit/ByHand";
import QRPayment from "../Deposit/QRPayment";
import CrediCardPayment from "../Deposit/CreditCard";
import TransferHistory from "../Cashier/TransferHistory";
import { fundTransfer } from "../../DataStore";
import { connect } from "react-redux";
import Withdrowal from "../WIthdrow/Withdrowal";
import FundTransfer from "../Cashier/FundTransfer";
import { IMAGE_BUCKET_URL, IMGBUCKETURL } from "../../DataStore/ApiConstant";
import FeaturedGames from "../General/FeaturedGames";
import GamesWrapper from "../General/GameboardWrapper";
import { DepositMenu, GameHistoryMenu, cashierTopMenu } from "../../constants";
import DepositNavBar from "../TransactionCommon/DepositNavBar";
import GameHistoryNavBar from "../HistoryCommon/GameHistoryNavBar";
import GameHistoryNavBarMobile from "../HistoryCommon/GameHistoryNavBarMobile";
import MainMenu from "../TransactionCommon/MainMenu";
import Redeposit from "../Deposit/Redeposit";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import { UserData } from "../../DataStore";
import WhatsappButton from "../Cashier/whatsappButton";
import UserInfo from "../Header/UserInfo";
import { useAtom } from "jotai";
import { reRenderCashier } from "../../DataStore/atom";
import PoyaBanneer from "../Poya/PoyaBanneer";
import CryptoBanner from "../Poya/CryptoBanner";
import Ewallet from "../Cashier/Ewallet";
import Points from "../Cashier/Points";
import Rolling from "../Cashier/Rolling";
import { useRolling, useWithdrawBonus } from "../../services/cashier";
import Crypto from "../Deposit/Crypto";
import { useState } from "react";

import Baccarat from "../HistoryCommon/Baccarat";

const Cashier = ({
  countryList,
  userData,
  getUserData,
  fundsTransfer,
  getFUndTransfers,
  openPopup,
  generalModalStatus,
  closePopup,
  generalModalType,
  openGeneralModal,
  selectedGameAreaType,
  setSelectedGameType,
  gameWrapperArea,
  cashierType,
  setCashierType,
  successMessage
}) => {
  // const [rolling, setRollingData] = useState(null);
  const [renderCashier, setRenderCashier] = useAtom(reRenderCashier);
  const userDta = userData.data.data1;
  const userSession = userData?.data?.session;
  const bottomRef = useRef(null);
  const history = useHistory();
  const rolling = useRolling();
  // const otpBalance = useOtpBalance();
  const bonus = useWithdrawBonus();
  // console.log(otpBalance);
  
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const checkLogin = () => {
    !localStorage.getItem('login')&&history.push('/')

  };
  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const goToCashier = (type = "deposit") => {
    setCashierType(type);
    history.push("/cashier");
    scrollToBottom();
  };
  
  useEffect(() => {
    checkLogin();
    getUserData();

    getFUndTransfers();
  }, []);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [renderCashier]);

  useEffect(() => {
    if (successMessage) {

      const cryptoTab = document.getElementById('v-pills-crypto-tab');
    
      // Check if the 'Crypto' tab button exists
      if (cryptoTab) {
        // Trigger a click event on the 'Crypto' tab button
        cryptoTab.click();
      }
      setShowSuccessMessage(true);

      const timer = setTimeout(() => {
        setShowSuccessMessage(false);
      }, 5000); // 5 seconds

      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  return (
    <>
      {/* header area */}
      <HeaderComponent
        login={true}
        openPopup={openPopup}
        setCashierType={setCashierType}
        openGeneralModal={openGeneralModal}
        setSelectedGameType={setSelectedGameType}
      />
      {/* banner area */}
      <MainBanner login={true} />

      {/* featured games */}
      <FeaturedGames
        login={true}
        openPopup={openPopup}
        cashier="true"
        setSelectedGameType={setSelectedGameType}
        openGeneralModal={openGeneralModal}
        goToCashier={goToCashier}
      />
      {/* game tabs */}

      <div
        class="container-fluid mt-lg-5 aos-init aos-animate"
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1500"
      >
        
        <img
          src={IMAGE_BUCKET_URL + "tab-inner-banner-new.jpg"}
          class="img-fluid mx-auto d-block border border-1"
          alt=""
        />
      </div>
      {/* {!userData.isLoading && userData?.data?.data1?.user_type == "demo" ? 
            <div  class="container-fluid mt-lg-5 py-5" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500" style={{ backgroundColor: "#181a1f" }} >
            <div ref={bottomRef} />
            <div class="row">
               <div class="d-flex flex-wrap  text-center gap-3 justify-content-around mb-4" style={{ fontSize: "1.3rem" }}>
                  Sorry, This feature is disabled in demo mode
                </div>
            </div>
          </div>
      :  */}

      <div
        class="container mt-lg-5 py-3"
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1500"
        style={{ backgroundColor: "#ededed", color:"#000" }}
      >
 

        {/* <div class="d-flex flex-wrap  text-center gap-3 justify-content-around mb-4">
          <div style={{color: "#000", fontSize: "1.3rem", fontWeight: 400, }}>
            <TranslatesContent contentKey="cash-balance" /> :{" "}
            <span style={{ color: "#000", fontWeight: 600 }}>
              {userData?.data?.data1?.amount
                ? new Intl.NumberFormat().format(userData?.data?.data1?.amount)
                : "0"}
            </span>
          </div>
          {userData?.data?.data1?.rolling == true ? 
            <div style={{color: "#000", fontSize: "1.3rem",fontWeight: 400, }}>
              Rolling Amount:{" "}
              <span style={{ color: "#000",fontWeight: 600}}>
                  {new Intl.NumberFormat().format(rolling?.data?.rollAmo)}
              </span>
            </div>
          : ""}
          <div style={{color: "#000", fontSize: "1.3rem",fontWeight: 400, }}>
            <div ref={bottomRef} />
            Non Cash :{" "}
            <span style={{ color: "#000",fontWeight: 600}}>
              {fundsTransfer &&
                    fundsTransfer.data &&
                    fundsTransfer.data.data.noncash && new Intl.NumberFormat().format(
                    fundsTransfer.data.data.noncash.balance
                  )}
            </span>
          </div>
          <div style={{color: "#000", fontSize: "1.3rem",fontWeight: 400, }}>
            <div ref={bottomRef} />
            OTP :{" "}
            <span style={{ color: "#000",fontWeight: 600}}>
            {fundsTransfer &&
                    fundsTransfer.data &&
                    fundsTransfer.data.data.otpcash && new Intl.NumberFormat().format(
                    fundsTransfer.data.data.otpcash.balance
                  )}
            </span>
          </div>
        </div>  */}


      
        
            <div ref={bottomRef} />

            {/* First Row - Cash, Non Cash, OTP */}
            <div className="row justify-content-center  align-items-center gx-2">
              <div className="col-md-3 col-6 mb-2">
                <div className="d-flex flex-row">
                  <div className="w-25 cashIconBox"><i className="fa-solid fa-money-bill-1-wave"></i></div>
                  <div className="w-75 cashTextBox">
                    <div style={{ fontSize: '1rem', fontWeight: 400 }}>Wallet Balance :</div>
                    <div style={{ fontSize: '1.2rem', fontWeight: 600 }}>
                      {fundsTransfer?.data?.data
                        ? new Intl.NumberFormat().format(Math.max(0, fundsTransfer.data.data.wallet.balance - fundsTransfer.data.data.bonus_given.balance))
                        : "0"}
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="col-md-3 col-6 mb-2">
                <div className="d-flex flex-row">
                  <div className="w-25 cashIconBox"><i className="fa-solid fa-envelopes-bulk"></i></div>
                  <div className="w-75 cashTextBox">
                    <div style={{ fontSize: '1rem', fontWeight: 400 }}>Achieved Points :</div>
                    <div style={{ fontSize: '1.2rem', fontWeight: 600 }}>
                      {bonus.isLoading === false
                        ? bonus?.data?.length > 0 && Math.floor(bonus?.data[0].achivedturnover)
                        : "0"}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-6 mb-2">
                <div className="d-flex flex-row">
                  <div className="w-25 cashIconBox"><i className="fa-solid fa-arrow-down-wide-short"></i></div>
                  <div className="w-75 cashTextBox">
                    <div style={{ fontSize: '1rem', fontWeight: 400 }}>OTP :</div>
                    <div style={{ fontSize: '1.2rem', fontWeight: 600 }}>
                      {fundsTransfer &&
                        fundsTransfer.data &&
                        fundsTransfer.data.data.otpcash && new Intl.NumberFormat().format(
                          fundsTransfer.data.data.otpcash.balance
                        )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-6 mb-2">
                <div className="d-flex flex-row">
                  <div className="w-25 cashIconBox"><i className="fa-solid fa-wallet"></i></div>
                  <div className="w-75 cashTextBox">
                    <div style={{ fontSize: '1rem', fontWeight: 400 }}>Non Cash :</div>
                    <div style={{ fontSize: '1.2rem', fontWeight: 600 }}>
                      {fundsTransfer &&
                        fundsTransfer.data &&
                        fundsTransfer.data.data.noncash && new Intl.NumberFormat().format(
                          fundsTransfer.data.data.noncash.balance
                        )}
                    </div>
                  </div>
                </div>
              </div>

              {userData?.data?.data1?.rolling === true && (
                <div className="col-md-3 col-6 mb-2">
                  <div className="d-flex flex-row">
                    <div className="w-25 cashIconBox"><i className="fa-solid fa-file"></i></div>
                    <div className="w-75 cashTextBox">
                      <div style={{ fontSize: '1rem', fontWeight: 400 }}>Rolling Amount :</div>
                      <div style={{ fontSize: '1.2rem', fontWeight: 600 }}>
                        {new Intl.NumberFormat().format(rolling?.data?.rollAmo)}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="col-md-3 col-6 mb-2">
                <div className="d-flex flex-row">
                  <div className="w-25 cashIconBox"><i className="fa-solid fa-box-open"></i></div>
                  <div className="w-75 cashTextBox">
                    <div style={{ fontSize: '1rem', fontWeight: 400 }}>Package Points :</div>
                    <div style={{ fontSize: '1.2rem', fontWeight: 600 }}>
                      {bonus.isLoading === false
                        ? bonus?.data?.length > 0 && bonus?.data[0]?.giventurnover
                        : "0"}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-6 mb-2">
                <div className="d-flex flex-row">
                  <div className="w-25 cashIconBox"><i className="fa-solid fa-arrow-down-wide-short"></i></div>
                  <div className="w-75 cashTextBox">
                    <div style={{ fontSize: '1rem', fontWeight: 400 }}>Sports Wallet (INR):</div>
                    <div style={{ fontSize: '1.2rem', fontWeight: 600 }}>
                      {fundsTransfer &&
                        fundsTransfer.data &&
                        fundsTransfer.data.data.sports && new Intl.NumberFormat().format(
                          fundsTransfer.data.data.sports.balance
                        )}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-3 col-6 mb-2">
                <div className="d-flex flex-row">
                  <div className="w-25 cashIconBox"><i className="fa-solid fa-box-open"></i></div>
                  <div className="w-75 cashTextBox">
                    <div style={{ fontSize: '1rem', fontWeight: 400 }}>Wallet (Without Bonus) :</div>
                    <div style={{ fontSize: '1.2rem', fontWeight: 600 }}>
                      {fundsTransfer &&
                        fundsTransfer.data &&
                        fundsTransfer.data.data.sports && new Intl.NumberFormat().format(
                          Math.max(0, fundsTransfer.data.data.wallet.fundBalance - fundsTransfer.data.data.bonus_given.balance)
                        )}
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
                {/* console.log(); */}
                
            {/* Second Row - Rolling Amount, Package Points, Achieved Points */}
            <div className="row justify-content-evenly align-items-center px-2">
              
            </div>

        <div class="row">
          <div class="col-12">
            <ul class="nav nav-tabsc" id="myTab" role="tablist">
              {/* {cashierTopMenu.map((item, i) => (
                <MainMenu object={item} unKey={i} />
              ))} */}
              {
                !userData.isLoading && userData?.data?.session?.typest !== "demo" ?
                  <>
                  <li class="nav-item col  px-2" role="presentation">
                    <button
                      className={
                        " nav-linkc " + (cashierType === "deposit" ? "active" : "")
                      }
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home"
                      onClick={() => setCashierType("deposit")}
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Deposit
                    </button>
                  </li>
                  <li class="nav-item col  px-2" role=" presentation">
                    <button
                      className={
                        " nav-linkc " + (cashierType === "withdrow" ? "active" : "")
                      }
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                      onClick={() => setCashierType("withdrow")}
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      Withdrawal
                    </button>
                  </li>
                  </> : ""
              }
              <li class="nav-item col  px-2" role="presentation">
                <button
                  className={
                    " nav-linkc " + (cashierType === "transfer" ? "active" : "")
                  }
                  id="contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#contact"
                  onClick={() => setCashierType("transfer")}
                  type="button"
                  role="tab"
                  aria-controls="contact"
                  aria-selected="false"
                >
                  Transactions
                </button>
              </li>
              <li class="nav-item col  px-2" role="presentation">
                <button
                  className={
                    " nav-linkc " + (cashierType === "history" ? "active" : "")
                  }
                  id="history-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#history"
                  onClick={() => setCashierType("history")}
                  type="button"
                  role="tab"
                  aria-controls="history"
                  aria-selected="false"
                >
                  Playlog
                </button>
              </li>
              {/* <li class="nav-item col  px-2" role="presentation">
                <button
                  className={
                    " nav-linkc " +
                    (cashierType === "fund-transfer" ? "active" : "")
                  }
                  id="transfer-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#transfer"
                  onClick={() => setCashierType("fund-transfer")}
                  type="button"
                  role="tab"
                  aria-controls="transfer"
                  aria-selected="false"
                >
                  Transfer
                </button>
              </li> */}
              {/* <li class="nav-item col  px-2" role="presentation">
                <button
                  className={
                    " nav-linkc " +
                    (cashierType === "eWallet" ? "active" : "")
                  }
                  id="eWallet-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#eWallet"
                  onClick={() => setCashierType("eWallet")}
                  type="button"
                  role="tab"
                  aria-controls="eWallet"
                  aria-selected="false"
                >
                  e-WALLET
                </button>
              </li> */}
              {/* {
                !userData.isLoading && userData?.data?.data1?.user_type !== "demo" && (
                  <li className="nav-item col px-2" role="presentation">
                    <button
                      className={
                        "nav-linkc " +
                        (cashierType === "fund-transfer" ? "active" : "")
                      }
                      id="transfer-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#transfer"
                      onClick={() => setCashierType("fund-transfer")}
                      type="button"
                      role="tab"
                      aria-controls="transfer"
                      aria-selected={cashierType === "fund-transfer"}
                    >
                      Transfer
                    </button>
                  </li>
                )
              } */}
              {!userData.isLoading && userData?.data?.session?.typest !== "demo" ?
                <li class="nav-item col  px-2" role="presentation">
                  <button
                    className={
                      " nav-linkc " +
                      (cashierType === "points" ? "active" : "")
                    }
                    id="points-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#points"
                    onClick={() => setCashierType("points")}
                    type="button"
                    role="tab"
                    aria-controls="points"
                    aria-selected="false"
                  >
                    Points
                  </button>
                </li> : ""}
                
              {userData?.data?.data1?.rolling == true ? 
              <li class="nav-item col  px-2" role="presentation">
                <button
                  className={
                    " nav-linkc " +
                    (cashierType === "rolling" ? "active" : "")
                  }
                  id="rolling-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#rolling"
                  onClick={() => setCashierType("rolling")}
                  type="button"
                  role="tab"
                  aria-controls="rolling"
                  aria-selected="false"
                >
                  Rolling
                </button>
              </li> : ""}
              {
                !userData.isLoading && userData?.data?.data1?.user_type !== "demo" && (
                  <li className="nav-item col px-2" role="presentation">
                    <button
                      className={
                        "nav-linkc " +
                        (cashierType === "fund-transfer" ? "active" : "")
                      }
                      id="transfer-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#transfer"
                      onClick={() => setCashierType("fund-transfer")}
                      type="button"
                      role="tab"
                      aria-controls="transfer"
                      aria-selected={cashierType === "fund-transfer"}
                    >
                      Transfer
                    </button>
                  </li>
                )
              }
            </ul>
            <div class="tab-content p-0 p-lg-3 p-md-3 p-sm-3" id="myTabContent">
              <div
                className={
                  (cashierType === "deposit" ? "show active" : "") +
                  " tab-pane fade"
                }
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div class="d-flex align-items-start ">
                  <div
                    class="nav flex-column nav-pills"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    {DepositMenu.map((item, i) => (
                      <DepositNavBar object={item} unKey={i} key={i} />
                    ))}
                  </div>
                  <div
                    class="tab-content p-4"
                    id="v-pills-tabContent"
                    style={{ width: "100%", backgroundColor: "transparent" }}
                  >
                    {/* upi payment */}
                    <UpiPayment />
                    {/* upi payment */}

                    {/* bank payment */}
                    <Bankayment />
                    {/* bank payment */}

                    {/*sampath bank payment */}
                    {/* <SampathBankayment /> */}
                    {/* bank payment */}
                    
                    {/* by Hand payment */}
                    <CrediCardPayment successMessage={successMessage} />
                    {/* by Hand payment */}

                    {/* by Hand payment */}
                    <ByHandPayment />

                    <QRPayment />
                    {/* by Hand payment */}
                    {/* redeposit html */}
                    <Redeposit />
                    <Crypto successMessage={successMessage}/> 
                  </div>
                </div>
              </div>
              {/* withdrowal wrapper */}
              <div
                className={
                  (cashierType === "withdrow" ? "show active" : "") +
                  " tab-pane fade"
                }
                id="profile"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                <Withdrowal countryList={countryList}/>
              </div>

              {/* transaction wrapper */}
              <div className={(cashierType === "transfer" ? "show active" : "") + " tab-pane fade"} id="contact" role="tabpanel" aria-labelledby="contact-tab">
                <TransferHistory />
              </div>

              {/* game history */}
              <div
                className={
                  (cashierType === "history" ? "show active" : "") +
                  " tab-pane fade"
                }
                id="history"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div class="d-flex align-items-start">
                  <div
                    class="nav flex-column nav-pills history-menu"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    {GameHistoryMenu.map((item, i) => (
                      <GameHistoryNavBar object={item} unKey={i} key={i} />
                    ))}
                  </div>
                  <div
                    class="nav flex-column nav-pills history-menu-mobile"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                    style={{position: "relative", marginBottom: "50%"}}
                  >
                    
                    <GameHistoryNavBarMobile object={GameHistoryMenu} />
                     
                  </div>
                  <div
                    class="tab-content p-4 history-table "
                    id="v-pills-tab"
                    style={{ width: "100%", backgroundColor: "transparent", paddingTop: "4.5rem !important" }}
                  >
                    {GameHistoryMenu.map((item, i) => (
                      <>
                        <Baccarat
                          game={item.key}
                          gameID={item.gameID}
                          i={i}
                          key={i}
                        />
                      </>
                    ))}
                  </div>
                </div>

              </div>
              {/* game history end */}

              <div className={(cashierType === "fund-transfer" ? "show active" : "") + " tab-pane fade"}id="transfer" role="tabpanel" aria-labelledby="contact-tab" >
                <FundTransfer goToCashier={goToCashier} />
              </div>
              <div className={(cashierType === "eWallet" ? "show active" : "") + " tab-pane fade" }id="ewallet" role="tabpanel" aria-labelledby="eWallet-tab">
                <Ewallet goToCashier={goToCashier} />
              </div>
              <div className={(cashierType === "points" ? "show active" : "") + " tab-pane fade" }id="points" role="tabpanel" aria-labelledby="points-tab">
                <Points setSelectedGameType={setSelectedGameType} goToCashier={goToCashier} />
              </div>
              <div className={(cashierType === "rolling" ? "show active" : "") + " tab-pane fade" }id="rolling" role="tabpanel" aria-labelledby="rolling-tab">
                <Rolling setSelectedGameType={setSelectedGameType} goToCashier={goToCashier} />
              </div>
            </div>
          </div>
        </div>
        {/* <WhatsappButton cashierType={cashierType}/> */}
      </div>
{/* } */}
      {/* footer area */}
      <FooterComponent
        login={true}
        openPopup={openPopup}
        generalModalStatus={generalModalStatus}
        closePopup={closePopup}
        generalModalType={generalModalType}
        openGeneralModal={openGeneralModal}
        goToCashier={goToCashier}

      />
      <UserInfo
        openPopup={openPopup}
        userSession={userSession}
        userDta={userDta}
        openGeneralModal={openGeneralModal}
        goToCashier={goToCashier}
        setSelectedGameType={setSelectedGameType}
        cashier="true"
      />
      <PoyaBanneer />
      <CryptoBanner />
    </>
  );
};

const maStateTorops = (state) => {
  return {
    fundsTransfer: state.FundTransfers,
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFUndTransfers: () => dispatch(fundTransfer()),
    getUserData: () => dispatch(UserData()),
  };
};

export default connect(maStateTorops, mapDispatchToProps)(Cashier);
